<template>
  <transition name="fade" mode="out-in" class="ts">
    <div :key="route.fullPath">
      <Header/>
      <main>
        <RouterView/>
      </main>
      <Footer/>
    </div>
  </transition>
</template>

<script setup lang="ts">
import {RouterView, useRoute, useRouter} from 'vue-router';
import { onMounted } from 'vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

import { routeCheck } from './utils/routes';

const route = useRoute();
const router = useRouter();
const consentURL = import.meta.env.VITE_CONSENT_URL || '';
import { setBathroomConfiguration } from './utils/configuration';

onMounted(async () => {
  setBathroomConfiguration();

  const script = document.createElement('script');
  script.src = consentURL;
  script.id = "vb-consent-manager";
  script.setAttribute('data-language-code', 'de');
  document.body.appendChild(script);
});
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>