<template>
  <footer>
    <div class="footer p--m" v-if="pageData" ref="footerRef">
      <div class="footer__col">
        <span class="footer__copyright">© {{ pageData.content.start_footer_copyright }} {{ new Date().getFullYear() }}</span>
      </div>
      <div class="footer__col">
        <div class="footer__nav" v-if="navItems">
          <a v-for="item in navItems" :href="item.nav_url" target="_blank">{{ item.nav_title }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {doQuery, getStartQuery} from "@/utils/queries";
import {ref, onMounted, onUpdated} from "vue";
import yaml from "js-yaml";

import {useGeneralStore} from "@/stores/general";
const generalStore = useGeneralStore();

const footerRef = ref(null);
const pageData = ref(null);
const navItems = ref([]);

onMounted(async () => {
  const pageStartQuery = getStartQuery("Start");
  pageData.value = await doQuery(pageStartQuery);
  if(pageData.value) {
    navItems.value = yaml.load(pageData.value.content.start_footer_nav);
  }
});

onUpdated(() => {
  if (footerRef.value) generalStore.footerHeight = footerRef.value.clientHeight;
});
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid var(--vb_grey_mid);
  background-color: var(--vb_grey_light);
  .footer__col {
    .footer__copyright {
      color: var(--vb_grey_dark);
    }
    .footer__nav {
      display: flex;
      gap: 16px;
      a {
        color: var(--vb_grey_dark);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    .footer__col {
      margin: 0 0 8px 0;
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}
</style>