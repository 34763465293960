<template>
  <div class="svg" :style="getMaxWidth()" v-html="svgContent"></div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';

const props = defineProps({
  path: {
    type: String,
    required: true
  },
  svg: {
    type: String,
    required: true
  },
  max: {
    type: String,
    default: 'auto'
  }
});

const svgContent = ref('');

const getMaxWidth = () => {
  return props.max === 'auto' ? 'max-width: 100%' : `max-width: ${props.max}px`;
};

// watchEffect(async () => {
//   const svgModulePath = `/svgs/${props.path}/${props.svg}.svg`;
//   try {
//     const svg = await import(/* @vite-ignore */ svgModulePath);
//     fetch(svg.default)
//         .then(response => response.text())
//         .then(data => {
//           svgContent.value = data;
//         });
//   } catch (error) {
//     console.error("Failed to load SVG:", error);
//   }
// });

watchEffect(async () => {
  const svgUrl = `/svgs/${props.path}/${props.svg}.svg`;
  try {
    const response = await fetch(svgUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch SVG: ${response.statusText}`);
    }
    const data = await response.text();
    svgContent.value = data;
  } catch (error) {
    console.error("Failed to load SVG:", error);
  }
});
</script>

<style scoped lang="scss">
.svg {}
</style>